import React, { useState, useEffect, SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Auth, Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { AuthVariant } from './AuthBox';
import ConfirmResetPassword from './ConfirmResetPassword';
import Container from '../../01_atoms/Container/Container';
import getUsernameByEmailAddress from '../../../utils/getUserEmailByAddress';
import gtagReportConversion from '../../../utils/gtagReportConversion';
import IconCheck from '../../../assets/icons/iconCheck.inline.svg';
import IconExclamation from '../../../assets/icons/iconExclamation.inline.svg';
import InformationModal, { IInformationScreen } from './InformationModal';
import ResetPassword from './ResetPassword';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import Text from '../../01_atoms/Text/Text';
import useAuth from '../../../hooks/useAuth';
import { navigate } from '@reach/router';
import ConfirmAuthCode from './ConfirmAuthCode';
import API from '../../../services/API';
import Spinner from '../../01_atoms/Spinner/Spinner';
import { useUser, useYougaClientApi } from '@youga/youga-client-api';
import BusinessSignInForm from './BusinessSignInForm';
import CodeInfoModal, {
  IInformationScreen as CodeIInformationScreen,
} from '../../03_organisms/MyAccountSection/CodeInformationModal';

interface AuthFormProps {
  confirmCode?: string;
  referral: string;
  variant?: AuthVariant;
  setCurrentVariant: (variant: AuthVariant) => void;
}

function debounce<T extends any[]>(
  fn: (...args: T) => Promise<void>,
  delay: number,
) {
  let timeout: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timeout);

    return new Promise<void>((resolve) => {
      timeout = setTimeout(async () => {
        await fn(...args);
        resolve();
      }, delay);
    });
  };
}

export default function AuthForm({
  confirmCode,
  referral = '',
  variant = 'signin',
  setCurrentVariant,
}: AuthFormProps) {
  const { t, i18n } = useTranslation();
  const { handleSignInWithEmail, refreshAuthContext } = useAuth();
  const { fetcher, api, userId } = useYougaClientApi();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [purchaseCode, setPurchaseCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const { token, fetchAndSetCurrentUser } = useAuth();
  const { refreshUser } = useUser();
  const [newsletter, setNewsletter] = useState('false');
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [codeInformationScreen, setCodeInformationScreen] =
    useState<CodeIInformationScreen | null>(null);

  const [errorInformation, setErrorInformation] = useState<null | {
    position: string;
    element: React.ReactNode;
  }>(null);
  const [code, setCode] = useState(confirmCode || '');
  const [informationScreen, setInformationScreen] =
    useState<IInformationScreen | null>(null);
  let title = '';

  switch (variant) {
    case 'signin':
      title = t('LOGE_DICH');
      break;
    case 'business_login':
      title = t('BUSINESS_LOGIN');
      break;
    case 'business_signup':
      title = t('BUSINESS_REGISTER');
      break;
    default:
      title = "Let's stretch together to achieve your goal";
      break;
  }

  let subtitle = '';

  switch (variant) {
    case 'resetPassword':
      subtitle = t('RESSET_YOUR_PASSWORD');
      break;
    case 'confirmResetPassword':
      subtitle = t('CONFIRM_RESET_PASSWORD');
      break;
    case 'business_signup':
      subtitle = t('BUSINESS_REGISTER_SUBTITLE');
      break;
    case 'business_login':
      subtitle = t('BUSINESS_LOGIN_SUBTITLE');
      break;
    default:
      subtitle = '';
      break;
  }

  useEffect(() => {
    setInformationScreen(null);

    setErrorInformation(null);
  }, [variant]);

  const federatedSignIn = async (
    provider: CognitoHostedUIIdentityProvider,
  ): Promise<void> => {
    try {
      const response = await Auth.federatedSignIn({ provider });
    } catch (e) {
      console.log('Federated login failed: provider: ', { provider });
      console.log(e);
    }
  };

  const onClickFacebookLogin = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    await federatedSignIn(CognitoHostedUIIdentityProvider.Facebook);
  };

  const clientCheckDebounced = useRef(
    debounce(async (client: string) => {
      setPurchaseCode(client);
    }, 600),
  ).current;

  const clientCheck = async (client: string) => {
    await clientCheckDebounced(client);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return true;
  };

  const onClickGoogleLogin = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    await federatedSignIn(CognitoHostedUIIdentityProvider.Google);
  };

  const onClickAppleLogin = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    await federatedSignIn(CognitoHostedUIIdentityProvider.Apple);
  };

  const onSubmit = async (
    e: SyntheticEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault();
    setInformationScreen(null);
    setErrorInformation(null);

    setLoading(true);

    try {
      switch (variant) {
        case 'signin':
          /**
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
           */
          try {
            await handleSignInWithEmail(email, password);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when a user is not confirmed successfully.
              case 'UserNotConfirmedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('CONFIRM_ACCOUT_FIRST')}</span>,
                });
                break;

              // This exception is thrown when a user is not found.
              case 'UserNotFoundException':
                caughtError = true;
                setInformationScreen({
                  title: t('USER_NOT_FOUND'),
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'warning',
                  description: '',
                  buttonLabel: t('REGISTER_BUTTON_LINK'),
                  buttonTargetVariant: 'business_signup',
                });
                break;

              // This exception is thrown when the Amazon Cognito service encounters an invalid password.
              case 'InvalidPasswordException':
                caughtError = true;
                setErrorInformation({
                  position: 'password',
                  element: <span>{t('INVALID_PASSWORD')}</span>,
                });
                break;

              // This exception is thrown when a user is not authorized.
              // Actually, this error is also thrown when a user does not exist or is not activated
              case 'NotAuthorizedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('INVALID_LOGIN_OR_PASSWORD')}</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
          }

          break;

        case 'business_signup':
          /**
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
           */
          try {
            setIsLoading(true);
            await Auth.signUp({
              username: getUsernameByEmailAddress(email),
              password,
              clientMetadata: {
                autoConfirm: 'true',
                locale: i18n.language !== 'pl' ? 'eng' : 'pl',
              },
              attributes: {
                email: email.trim().toLowerCase(),
                'custom:referral': 'app',
                'custom:locale': i18n.language !== 'pl' ? 'eng' : 'pl',
                'custom:terms_agreed': 'true',
                'custom:newsletter': 'true',
                'custom:code': purchaseCode.length !== 6 ? '-' : purchaseCode,
              },
            });

            const resultSignIn = await Auth.signIn(
              // if the email address is not yet verified, we need to give the username here instead the email address
              getUsernameByEmailAddress(email),
              password,
            );

            console.log(`SignUp::signin::result:`, resultSignIn);
            await Auth.verifyCurrentUserAttribute('email');

            setInformationScreen({
              title: t('EMAIL_SENT'),
              description: t('ENTER_CODE_FROM_EMAIL'),
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: t('ENTER_THE_CODE'),
              buttonTargetVariant: 'confirmSignup',
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when the Amazon Cognito service encounters an invalid parameter.
              case 'InvalidParameterException':
              case 'InvalidPasswordException':
                if (
                  error.message.includes(
                    "Value at 'password' failed to satisfy constraint",
                  ) ||
                  error.message.includes(
                    'Password did not conform with policy',
                  ) ||
                  error.message.includes('Password does not conform to policy')
                ) {
                  caughtError = true;
                  setErrorInformation({
                    position: 'password',
                    element: (
                      <span>
                        {t('PASSWORD_NOT_MEET_REQUIREMENTS')}{' '}
                        <span
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          sx={{ backgroundColor: 'grey1', px: 1 }}
                        >
                          {
                            ' + = ^ $ * . [ ] { } ( ) ? " ! @ # % & /  , > < \' : ; | _ ~ ` '
                          }
                        </span>
                      </span>
                    ),
                  });
                  break;
                }

                if (error.message.includes('Invalid email address format.')) {
                  caughtError = true;
                  setErrorInformation({
                    position: 'email',
                    element: <span>{t('EMAIL_INVALID')}</span>,
                  });
                  break;
                }

                break;

              // This exception is thrown when Amazon Cognito encounters a user name that already exists in the user pool.
              case 'UsernameExistsException':
                caughtError = true;
                setInformationScreen({
                  title: t('USER_ALREADY_EXISITS'),
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'warning',
                  description: '',
                  buttonLabel: t('LOGIN_BUTTON_LINK'),
                  buttonTargetVariant: 'signin',
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
          } finally {
            setIsLoading(false);
          }
          break;

        case 'confirmSignup':
          /**
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
           */
          try {
            // refreshAuthContext(code.trim());

            // navigate('/app');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setIsLoading(true);
            await Auth.verifyCurrentUserAttributeSubmit('email', code);
            if (token) {
              await API.postUserAttributes(token, { email_verified: true });
            }
            await fetchAndSetCurrentUser();
            await refreshUser();
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown if the provided code does not match what the server was expecting.
              case 'CodeMismatchException':
                caughtError = true;
                setErrorInformation({
                  position: 'code',
                  element: <span>{t('ACTIVATION_CODE_INVALID')}</span>,
                });
                break;

              // This exception is thrown if a code has expired.
              case 'ExpiredCodeException':
                // @todo add instructions on how to retrieve new code
                caughtError = true;
                setInformationScreen({
                  allowClose: true,
                  title: t('CODE_EXPIRED'),
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'error',
                  description: (
                    <span>
                      {t('FEEL_FREE_TO_CONTACT_US')}
                      <br />
                      <a href={`mailto:${t('INFO_MAIL')}`}>{t('INFO_MAIL')}</a>
                    </span>
                  ),
                });
                break;

              case 'UserNotFoundException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('EMAIL_NOT_FOUND')}</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
            break;
          } finally {
            setIsLoading(false);
          }

          // send this as a conversion to google
          await gtagReportConversion();

          // We know that the user came here from the previous steps -> direct login because we know the password
          if (password) {
            try {
              /**
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
               */
              const response = await Auth.signIn(
                email.trim().toLowerCase().replace('@', '_at_'),
                password,
              );
              if (!response.data.attributes.email_verified) {
                setCurrentVariant('confirmSignup');
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              // This is a special case here, because the "signin" is somehow optional
              // if it fails, we display the user an information screen and redirect him to the login
              setInformationScreen({
                title: t('ACTIVATION_SUCCESFULL'),
                description: t('ACCOUNT_ACTIVATED'),
                iconColor: 'primary',
                icon: IconCheck,
                buttonLabel: t('LOGIN_BUTTON_LINK'),
                buttonTargetVariant: 'signin',
              });
            }
          } else {
            setInformationScreen({
              title: t('ACTIVATION_SUCCESFULL'),
              description: t('ACCOUNT_ACTIVATED'),
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: t('NEXT'),
              buttonTargetVariant: 'confirmSignup',
            });
          }
          break;

        case 'resetPassword':
          try {
            /**
             * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
             */
            await Auth.forgotPassword(email.trim().replace('@', '_at_'));

            // Unfortunately we do not get any error information on whether the user exists or not
            // so we need to display a generic help message in case the user does not get any email
            setInformationScreen({
              title: t('EMAIL_SENT'),
              description: (
                <React.Fragment>
                  <p>{t('ENTER_CODE_TO_CHANGE_PASSWORD')}</p>
                  <p>
                    {t('CONTACT_US_IF_YOU_HAVENT_RECIEVE_EMAIL')}{' '}
                    <a href={`mailto:${t('INFO_MAIL')}`}>{t('INFO_MAIL')}</a>
                  </p>
                </React.Fragment>
              ),
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: t('ENTER_THE_CODE'),
              buttonTargetVariant: 'confirmResetPassword',
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when a user is not confirmed successfully.
              case 'UserNotConfirmedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('CONFIRM_ACCOUT_FIRST')}</span>,
                });
                break;

              // This exception is thrown when a user is not found.
              case 'UserNotFoundException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('EMAIL_NOT_FOUND')}</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
          }
          break;

        case 'confirmResetPassword':
          try {
            /**
             * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html
             */
            await Auth.forgotPasswordSubmit(
              email.trim().replace('@', '_at_'),
              code.trim(),
              password,
            );
            navigate('/app');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when the Amazon Cognito service encounters an invalid parameter.
              case 'InvalidParameterException':
              case 'InvalidPasswordException':
                if (
                  error.message.includes(
                    "Value at 'password' failed to satisfy constraint",
                  ) ||
                  error.message.includes(
                    'Password did not conform with policy',
                  ) ||
                  error.message.includes('Password does not conform to policy')
                ) {
                  caughtError = true;
                  setErrorInformation({
                    position: 'password',
                    element: (
                      <span>
                        {t('PASSWORD_NOT_MEET_REQUIREMENTS')}{' '}
                        <span
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          sx={{ backgroundColor: 'grey1', px: 1 }}
                        >
                          {
                            ' + = ^ $ * . [ ] { } ( ) ? " ! @ # % & /  , > < \' : ; | _ ~ ` '
                          }
                        </span>
                      </span>
                    ),
                  });
                  break;
                }
                break;

              // This exception is thrown if the provided code does not match what the server was expecting.
              case 'CodeMismatchException':
                caughtError = true;
                setErrorInformation({
                  position: 'code',
                  element: <span>{t('ACTIVATION_CODE_INVALID')}</span>,
                });
                break;

              // This exception is thrown if a code has expired.
              case 'ExpiredCodeException':
                // @todo add instructions on how to retrieve new code
                caughtError = true;
                setInformationScreen({
                  allowClose: true,
                  title: 'Dein Code ist abgelaufen.',
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'error',
                  description: (
                    <span>
                      {t('FEEL_FREE_TO_CONTACT_US')}
                      <br />
                      <a href={`mailto:${t('INFO_MAIL')}`}>{t('INFO_MAIL')}</a>
                    </span>
                  ),
                });
                break;

              // This exception is thrown when a user is not confirmed successfully.
              case 'UserNotConfirmedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('CONFIRM_ACCOUNT_FIRST')}</span>,
                });
                break;

              // This exception is thrown when a user is not found.
              case 'UserNotFoundException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('EMAIL_NOT_FOUND')}</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
            break;
          }

          // We know that the user came here from the previous steps -> direct login because we know the password
          if (password) {
            try {
              /**
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
               * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
               */
              await Auth.signIn(
                email.trim().toLowerCase().replace('@', '_at_'),
                password,
              );
            } catch (error) {
              // This is a special case here, because the "signin" is somehow optional
              // if it fails, we display the user an information screen and redirect him to the login
              setInformationScreen({
                title: t('PASSWORD_CHANGED'),
                description: t('PASSWORD_CHANGED_SUCCESSFULLY'),
                iconColor: 'primary',
                icon: IconCheck,
                buttonLabel: t('LOGIN_BUTTON_LINK'),
                buttonTargetVariant: 'signin',
              });
            }
          } else {
            setInformationScreen({
              title: t('PASSWORD_CHANGED'),
              description: t('PASSWORD_CHANGED_SUCCESSFULLY'),
              iconColor: 'primary',
              icon: IconCheck,
              buttonLabel: t('LOGIN_BUTTON_LINK'),
              buttonTargetVariant: 'signin',
            });
          }
          break;

        case 'business_login':
          /**
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
           * @see https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GetUser.html
           */
          try {
            await handleSignInWithEmail(email, password);
            if (purchaseCode.length > 0) {
              try {
                const newToken: string = await fetchAndSetCurrentUser();
                if (newToken) {
                  await API.applyVoucher(newToken, purchaseCode);
                  setCodeInformationScreen({
                    allowClose: true,
                    title: t('voucher_activated'),
                    description: t('voucher_activated_desc'),
                    buttonLabel: t('voucher_activated_label'),
                    iconColor: 'success',
                    onLabelClick: async () => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      window.location = '/app';
                    },
                  });
                }
              } catch (error) {
                setCodeInformationScreen({
                  allowClose: true,
                  title: t('voucher_code_invalid'),
                  description: t('voucher_code_invalid_desc'),
                  buttonLabel: t('voucher_code_invalid_label'),
                  iconColor: 'error',
                });
                break;
              }
            } else {
              await fetchAndSetCurrentUser();
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window.location = '/app';
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            let caughtError = false;

            // We only want to catch API specific errors here
            // in all other cases, we throw the error one level higher
            switch (error.code) {
              // This exception is thrown when a user is not confirmed successfully.
              case 'UserNotConfirmedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('CONFIRM_ACCOUT_FIRST')}</span>,
                });
                break;

              // This exception is thrown when a user is not found.
              case 'UserNotFoundException':
                caughtError = true;
                setInformationScreen({
                  title: t('USER_NOT_FOUND'),
                  icon: IconExclamation, // @todo find a better icon
                  iconColor: 'warning',
                  description: '',
                  buttonLabel: t('REGISTER_BUTTON_LINK'),
                  buttonTargetVariant: 'business_signup',
                });
                break;

              // This exception is thrown when the Amazon Cognito service encounters an invalid password.
              case 'InvalidPasswordException':
                caughtError = true;
                setErrorInformation({
                  position: 'password',
                  element: <span>{t('INVALID_PASSWORD')}</span>,
                });
                break;

              // This exception is thrown when a user is not authorized.
              // Actually, this error is also thrown when a user does not exist or is not activated
              case 'NotAuthorizedException':
                caughtError = true;
                setErrorInformation({
                  position: 'email',
                  element: <span>{t('INVALID_LOGIN_OR_PASSWORD')}</span>,
                });
                break;

              default:
                caughtError = false;
                break;
            }

            if (!caughtError) {
              throw error;
            }
          }

          break;

        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      Sentry.configureScope((scope) => {
        scope.setExtra('errorCode', err?.code);
        scope.setExtra('errorCodeB64', btoa(err?.code));
        scope.setExtra('errorMessage', err?.message);
        scope.setExtra('errorMessageB64', btoa(err?.message));
        scope.setExtra('errorName', err?.name);
        scope.setExtra('errorNameB64', btoa(err?.name));
        Sentry.captureException(
          new Error('Signup Flow Uncaught Cognito Error Response'),
        );
      });

      setInformationScreen({
        allowClose: true,
        title: 'Sorry!',
        icon: IconExclamation, // @todo find a better icon
        iconColor: 'warning',
        description: (
          <span>
            {t('SOMETHING_WENT_WRONG')} <br />
            {t('FEEL_FREE_TO_CONTACT_US')}
            <br />
            <a href={`mailto:${t('INFO_MAIL')}`}>{t('INFO_MAIL')}</a>
          </span>
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InformationModal
        informationScreen={informationScreen}
        setInformationScreen={setInformationScreen}
        setCurrentVariant={setCurrentVariant}
      />

      <form
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sx={{ m: 0, p: 0 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSubmit={onSubmit}
      >
        <Container sx={{ width: '24rem' }}>
          <Text variant="h1" style={{ marginBottom: '2rem' }}>
            {title}
          </Text>

          <Text
            style={{
              color: 'rgb(17, 17, 17)',
              fontSize: '24px',
              fontWeight: 'bold',
              marginBottom: '2rem',
            }}
          >
            {subtitle}
          </Text>

          {variant === 'signin' && (
            <SignInForm
              errorInformation={errorInformation}
              // onClickFacebookLogin={onClickFacebookLogin}
              onClickGoogleLogin={onClickGoogleLogin}
              onClickAppleLogin={onClickAppleLogin}
              setEmail={setEmail}
              setPassword={setPassword}
              setCurrentVariant={setCurrentVariant}
              loading={loading}
            />
          )}

          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            variant === 'signInWithCode' && (
              <SignInForm
                errorInformation={errorInformation}
                // onClickFacebookLogin={onClickFacebookLogin}
                onClickGoogleLogin={onClickGoogleLogin}
                onClickAppleLogin={onClickAppleLogin}
                setEmail={setEmail}
                setPassword={setPassword}
                setCurrentVariant={setCurrentVariant}
                loading={loading}
              />
            )
          }

          {variant === 'resetPassword' && (
            <ResetPassword
              errorInformation={errorInformation}
              setCurrentVariant={setCurrentVariant}
              setEmail={setEmail}
            />
          )}

          {variant === 'confirmResetPassword' && (
            <ConfirmResetPassword
              confirmCode={confirmCode}
              errorInformation={errorInformation}
              setCurrentVariant={setCurrentVariant}
              setCode={setCode}
              setPassword={setPassword}
            />
          )}

          {variant === 'confirmResetPassword' && (
            <ConfirmResetPassword
              confirmCode={confirmCode}
              errorInformation={errorInformation}
              setCurrentVariant={setCurrentVariant}
              setCode={setCode}
              setPassword={setPassword}
            />
          )}

          {variant === 'confirmSignup' && (
            <ConfirmAuthCode
              confirmCode={confirmCode}
              errorInformation={errorInformation}
              setCurrentVariant={setCurrentVariant}
              setCode={setCode}
              setPassword={setPassword}
              setIsLoading={setIsLoading}
            />
          )}

          {variant === 'business_signup' && (
            <SignUpForm
              errorInformation={errorInformation}
              onClickFacebookLogin={onClickFacebookLogin}
              onClickGoogleLogin={onClickGoogleLogin}
              onClickAppleLogin={onClickAppleLogin}
              setEmail={setEmail}
              clientCheck={clientCheck}
              setPassword={setPassword}
              setCurrentVariant={setCurrentVariant}
              conditionsAccepted={conditionsAccepted}
              setConditionsAccepted={setConditionsAccepted}
              newsletter={newsletter}
              setNewsletter={setNewsletter}
              setCode={setCode}
              confirmCode={confirmCode}
            />
          )}
          {variant === 'business_login' && (
            <BusinessSignInForm
              errorInformation={errorInformation}
              setEmail={setEmail}
              clientCheck={clientCheck}
              setPassword={setPassword}
              isLoading={isLoading}
            />
          )}
        </Container>
        <CodeInfoModal
          informationScreen={codeInformationScreen}
          setInformationScreen={setCodeInformationScreen}
        />
      </form>
      {isLoading && <Spinner fullscreen />}
    </>
  );
}
